import React from "react"
import { Link, graphql } from "gatsby";
import Helmet from 'react-helmet';
import Navbar from "../components/navbar";
import PromoBar from "../components/PromoBar";
import SevenDaysBanner from "../components/SevenDaysBanner";
import Footer from "../components/Footer";

export default ({data}) => {
    const image = '/img/hero/content/mobile-funk-alarmanlagen-vorteile.jpg';
    const contextData = {
        title: data.site.siteMetadata.title,
        image: image,
        image768: '/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-mobile2.jpg',
        image1024: image,
        image1216: image,
        image1408: image,
        showPriceCalculator: false,
        showHeroMobile: false,
        showBottom: true,
    };
    const articles = data.allMarkdownRemark.edges

    const articleCards = articles.map((item, key) => {
        let frontmatter = item.node.frontmatter;
        let image = '/img/hero/content/' + frontmatter.image;
        return(
            <div key={key} className="column is-one-third">
                <Link to={"/"+frontmatter.slug}>
                    <div className="card" >
                        <figure className="image is-2by1">
                            <img src={image} alt="Article image"/>
                        </figure>
                        <div className="card-content">
                            <h4><b>{frontmatter.title}</b></h4>
                            <p className="is-hidden-mobile" >{frontmatter.description}</p>
                        </div>
                    </div>
                </Link>
            </div>
        );
    })
    return (
        <React.Fragment>
            <header className="header">
                <Helmet>
                    <html className='has-navbar-fixed-top' />
                    <title>Jetzt Preis für Ihre Alarmanlage berechnen | MeinAlarm24</title>
                    <meta name="description" content="Berechnen Sie in wenigen Minuten den Preis für Ihre Alarmanlage. ✓ Kostenlos ✓ Sicher ✓ Festpreis " />
                    <meta name="keywords" content="" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content='Jetzt Preis für Ihre Alarmanlage berechnen | MeinAlarm24' />
                    <meta property="og:description" content="Berechnen Sie in wenigen Minuten den Preis für Ihre Alarmanlage. ✓ Kostenlos ✓ Sicher ✓ Festpreis " />
                    <meta property="og:image" content={image} />
                </Helmet>
                <PromoBar context={contextData} />
                <Navbar context={contextData}/>
            </header>
            <div className="ratgeber-page">
                <div className="page-content container">
                    <div className="headline">
                        <h2 className="title">Der MeinAlarm24-Ratgeber</h2>
                        <h3>In diesem Ratgeber finden Sie viele Informationen rund um das Thema Sicherheitstechnik, Alarmanlagen & Einbruchsprävention</h3>
                    </div>
                    <div className="columns cards is-multiline">
                        {articleCards}
                    </div>
                </div>
                <SevenDaysBanner />
                <Footer />
            </div>
        </React.Fragment>
    );
}

export const query = graphql`
  query contentArticleQuery {
    allMarkdownRemark
      (
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {fileAbsolutePath: {regex: "/content-articles/"}}
      )
      {
        edges{
          node{
            frontmatter{
              title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
                slug
                image
                image768
                image1024
                image1216
                image1408
            }
          }
        }
      }
    site {
        siteMetadata {
          title
          image
          image768
          image1024
          image1216
          image1408
        }
    }
  }
`;
